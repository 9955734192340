<template>
  <v-app>
    <v-main>
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="text-center mb-5 font-weight-light display-1">
              <span class="green--text">Mega</span>
              <span class="primary--text">Pass</span>
              <span class="red--text"> Business</span>
            </div>
            <v-card>
              <v-card-text>
                <v-form v-model="isValid">
                  <v-text-field
                    label="Логин"
                    outlined
                    autofocus
                    v-model="username"
                  ></v-text-field>
                  <v-text-field
                    label="Пароль"
                    outlined
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="send"
                  ></v-text-field>
                  <v-btn
                    block
                    x-large
                    depressed
                    :loading="isLoading"
                    color="primary"
                    class="text-capitalize font-weight-regular"
                    :class="{ 'disable-events': !username && !password }"
                    @click="send"
                    >Войти</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- must be declared only once in app components -->
    <Snackbar />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Snackbar from "./Snackbar.vue";

export default {
  components: { Snackbar },
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      isValid: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.progressBar.isLoading,
    }),
  },
  methods: {
    send() {
      const that = this;
      const credentials = {
        username: this.username,
        password: this.password,
        success: () => that.$router.push({ name: "app" }),
      };
      this.login(credentials);
    },
    ...mapActions(["login"]),
  },
};
</script>